<template>
    <div class="flex flex-col items-center m-4">
      <div class="my-2">
          <idc-logo width="100%" height="48px" color="black"/>
      </div>
      <div class="w-full xl:w-1/2 space-y-2 m-4">
        <form
          class="space-y-6 pb-4"
          @submit.prevent="submit"
          @keydown="removeError($event.target.id)"
          @input="removeError($event.target.id)">
          <h3 class="text-3xl text-center">
            Preinscripción
          </h3>
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Datos personales
            </h3>
            <a-input
              required
              label="Correo electrónico"
              :disabled="loadingStudents || loadings.reinscription || isFounded || isReinscription"
              type="email"
              id="email"
              :error="error('email', { attribute: 'correo electrónico' })"
              v-model="preinscription.email" />

            <a-input
              id="id_document"
              required
              label="Cédula"
              :loading="loadingStudents || loadings.reinscription"
              :error="error('id_document', { attribute: 'cédula' })"
              v-model="preinscription.id_document" />

            <div class="flex flex-row space-x-2 sm:flex-column">
                <a-input
                  label="Nombres"
                  class="w-full"
                  placeholder="Primero"
                  required
                  id="first_name"
                  :error="error('first_name', { attribute: 'nombre' })"
                  v-model.number="preinscription.first_name" />

                <div class="flex flex-col w-full">
                  <a-input
                    class="w-full mt-6"
                    placeholder="Segundo (opcional)"
                    id="second_name"
                    :error="error('second_name', { attribute: 'segundo nombre' })"
                    v-model.number="preinscription.second_name" >
                    <template  #suffix>
                      <div @click="handleDisplay(true)">
                        <a-icon v-if="displayNotApplyName" id="second_name_arrow" name="arrow-down" class="mr-2 cursor-pointer transform rotate-180" />
                        <a-icon v-else name="arrow-down" class="mr-2 cursor-pointer" />
                      </div>
                    </template>
                  </a-input>
                  <ul v-show="displayNotApplyName" class="bg-white border rounded-md shadow-lg z-50 w-full">
                    <li @click="handleSelection('No aplica', true)" class="px-3 py-2 rounded-md hover:bg-blue-600 hover:text-white cursor-pointer">
                      No aplica
                    </li>
                  </ul>
                </div>
            </div>
            <div class="flex flex-row space-x-2">
                <a-input
                  label="Apellidos"
                  class="w-full"
                  placeholder="Primero"
                  required
                  id="last_name"
                  :error="error('last_name', { attribute: 'apellido' })"
                  v-model="preinscription.last_name" />

                <div class="flex flex-col w-full">
                  <a-input
                    class="w-full mt-6"
                    placeholder="Segundo (opcional)"
                    id="second_last_name"
                    :error="error('second_last_name', { attribute: 'segundo apellido' })"
                    v-model="preinscription.second_last_name">
                    <template  #suffix>
                      <div @click="handleDisplay(false)">
                        <a-icon v-if="displayNotApplyLastName" id="second_last_name_arrow" name="arrow-down" class="mr-2 cursor-pointer transform rotate-180" />
                        <a-icon v-else name="arrow-down" class="mr-2 cursor-pointer" />
                      </div>
                    </template>
                  </a-input>
                  <ul v-show="displayNotApplyLastName" class="bg-white border rounded-md shadow-lg z-50 w-full">
                    <li @click="handleSelection('No aplica', false)" class="px-3 py-2 rounded-md hover:bg-blue-600 hover:text-white cursor-pointer">
                      No aplica
                    </li>
                  </ul>
                </div>
            </div>
  
            <div class="flex flex-row space-x-2">
              <a-input
                label="Teléfono"
                class="w-full"
                id="phone"
                v-model="preinscription.phone" />

              <a-input
                label="Teléfono celular"
                class="w-full"
                required
                id="mobile_phone"
                :error="error('mobile_phone', { attribute: 'teléfono celular' })"
                v-model="preinscription.mobile_phone" />
            </div>
            <div class="flex flex-row space-x-2">
              <a-input
                label="Lugar de nacimiento"
                class="w-full"
                required
                id="birth_place"
                :error="error('birth_place', { attribute: 'lugar de nacimiento' })"
                v-model="preinscription.birth_place" />
    
              <a-input
                label="Fecha de nacimiento"
                class="w-full"
                required
                id="birth_date"
                type="date"
                :error="error('birth_date', { attribute: 'fecha de nacimiento', format: (value) => value, date: (value) => value })"
                v-model="preinscription.birth_date" />
            </div>

            <a-select
            label="Género"
            required
            :source="[{label: 'Masculino', value: 'male'}, {label: 'Femenino', value: 'female'}]"
            :error="error('gender', { attribute: 'género' })"
            v-model="preinscription.gender" />

            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                  ¿Posee alguna condición?
                </label>
                <span class="text-red-600 inline-block ml-1 font-bold">
                  *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="has_condition" 
                    :value="false"
                    v-model="hasCondition" 
                    class="form-radio h-4 w-4 text-blue-600">
                  <span class="ml-2">No</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="has_condition" 
                    :value="true"
                    v-model="hasCondition" 
                    class="form-radio h-4 w-4 text-blue-600">
                  <span class="ml-2">Sí</span>
                </label>
              </div>
              
              <a-input
                v-if="hasCondition"
                label="Especifique su condición"
                required
                id="personal_condition"
                :error="error('personal_condition', { attribute: 'condición' })"
                v-model="preinscription.personal_condition"
                class="mt-4" />
            </div>
          </div>
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Dirección
            </h3>
            <div class="flex flex-row space-x-2">
              <a-input
                label="Estado"
                class="w-full"
                required
                id="state"
                :error="error('state', { attribute: 'estado' })"
                v-model="preinscription.address.state" />
              <a-input
                label="Ciudad"
                class="w-full"
                required
                id="city"
                :error="error('city', { attribute: 'ciudad' })"
                v-model="preinscription.address.city" />
            </div>

            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                    Ubicación de vivienda
                </label>
                <span class="text-red-600 inline-block ml-1 font-bold">
                    *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Urbanización" 
                    v-model="preinscription.address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Urbanización</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Barrio" 
                    v-model="preinscription.address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Barrio</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Sector" 
                    v-model="preinscription.address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Sector</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_location" 
                    value="Avenida" 
                    v-model="preinscription.address.location" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_location')">
                  <span class="ml-2">Avenida</span>
                </label>
              </div>
              <p v-if="error('address_location', { attribute: 'ubicación' })" class="mt-2 text-sm text-red-600">
                {{ error('address_location', { attribute: 'ubicación' }) }}
              </p>
            </div>
            <a-input
              required
              id="address"
              :error="error('address', { attribute: 'dirección' })"
              v-model="preinscription.address.address" />
              
            <div>
              <div class="flex">
                <label class="font-medium block mb-2">
                    Tipo de vivienda
                </label>
                <span class="text-red-600 inline-block ml-1 font-bold">
                    *
                </span>
              </div>
              <div class="flex flex-row space-x-4">
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="edificio" 
                    v-model="preinscription.address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Edificio</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="residencia" 
                    v-model="preinscription.address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Residencia</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="conjunto residencial" 
                    v-model="preinscription.address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Conjunto Residencial</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="quinta" 
                    v-model="preinscription.address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Quinta</span>
                </label>
                <label class="inline-flex items-center">
                  <input type="radio" 
                    name="address_type" 
                    value="casa" 
                    v-model="preinscription.address.type" 
                    class="form-radio h-4 w-4 text-blue-600"
                    @change="removeError('address_type')">
                  <span class="ml-2">Casa</span>
                </label>
              </div>
              <p v-if="error('address_type', { attribute: 'tipo' })" class="mt-2 text-sm text-red-600">
                {{ error('address_type', { attribute: 'tipo' }) }}
              </p>
            </div>
            <a-input
              required
              id="address_number"
              :error="error('address_number', { attribute: 'número' })"
              v-model="preinscription.address.number" /> 
          </div>
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Redes sociales
            </h3>
            <a-input
              label="Instagram"
              id="instagram"
              :error="error('social_networks.instagram', { attribute: 'instagram' })"
              v-model="preinscription.social_networks.instagram" />
  
            <a-input
              label="Facebook"
              id="facebook"
              :error="error('social_networks.facebook', { attribute: 'facebook' })"
              v-model="preinscription.social_networks.facebook" />
            <a-input
              label="Twitter"
              id="twitter"
              :error="error('social_networks.twitter', { attribute: 'x' })"
              v-model="preinscription.social_networks.twitter" />
          </div>
  
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Estudios realizados
            </h3>
  
            <a-select
              label="Educación"
              required
              :source="[{label: 'Media', value: 'medium'}, {label: 'Superior', value: 'superior'}]"
              :error="error('educational_background', { attribute: 'educación' })"
              v-model="preinscription.educational_background" />
  
            <a-input
              label="Título obtenido"
              required
              id="educational_title"
              :error="error('educational_title', { attribute: 'título obtenido' })"
              v-model="preinscription.educational_title" />
  
            <a-input
              label="Actividad actual"
              required
              id="educational_current_activity"
              :error="error('educational_current_activity', { attribute: 'actividad actual' })"
              v-model="preinscription.educational_current_activity" />
  
            <a-input
              label="Nombre de instituto"
              required
              id="educational_institution_name"
              :error="error('educational_institution_name', { attribute: 'nombre de instituto' })"
              v-model="preinscription.educational_institution_name" />
  
            <a-input
              label="Fecha de graduación"
              required
              id="educational_graduation_date"
              type="date"
              :error="error('educational_graduation_date', { attribute: 'fecha de graduación', format: (value) => value, date: (value) => value })"
              v-model="preinscription.educational_graduation_date" />
  
            <a-select
              label="Especialidad"
              required
              id="specialization_id"
              :disabled="loadingStudents || loadings.reinscription"
              @input="removeError('specialization_id')"
              :loading="loadingSpecialization"
              :source="specializationsData"
              :error="error('specialization_id', { attribute: 'especialidad' })"
              v-model="preinscription.specialization_id"/>

              <div>
              <div class="flex">
                <label class="font-medium text-gray-700 block mb-2">
                  Turno
                </label>
                <span class="text-red-600 inline-block ml-1 font-bold">
                  *
                </span>
              </div>
              <v-select
                :loading="loadings.enabledPeriod"
                :options="period.settings.schedule_shifts"
                :reduce="$0 => $0.value"
                v-model="preinscription.schedule_shift" />
              <p class="text-red-500" v-if="error('schedule_shift')">{{ error('schedule_shift', { attribute: 'turno' }) }}</p>
            </div>
          </div>
  
          <!-- ------------------------------------- -->
  
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Datos de persona de contacto
            </h3>
            <a-input
                label="Nombre y apellido"
                required
                id="representative_name"
                :error="error('representative_name', { attribute: 'nombre y apellido' })"
                v-model="preinscription.representative_name" />
  
            <a-input
              label="Profesión"
              required
              id="representative_profession"
              :error="error('representative_profession', { attribute: 'profesión' })"
              v-model="preinscription.representative_profession" />

            <a-input
              label="Correo"
              required
              id="representative_email"
              :error="error('representative_email', { attribute: 'email' })"
              v-model="preinscription.representative_email" />
  
              <h3 class="font-bold text-gray-800 text-lg">
                Dirección
              </h3>
              <div class="flex items-center">
                <input
                    type="checkbox"
                    class="mr-2"  
                    v-model="sameAsStudentAddress"
                    @input="(e) => handleSameAsStudentAddress(e.target.checked)"
                />
                    La dirección de la persona de contacto es la misma que la del estudiante
                
              </div>
              <div class="flex flex-row space-x-2">
                <a-input
                  label="Estado"
                  class="w-full"
                  :disabled="sameAsStudentAddress"
                  :required="!sameAsStudentAddress"
                  id="representative_address_state"
                  :error="error('representative_address.state', { attribute: 'estado' })"
                  v-model=" preinscription.representative_address.state" />
                <a-input
                  label="Ciudad"
                  class="w-full"
                  :disabled="sameAsStudentAddress"
                  :required="!sameAsStudentAddress"
                  id="representative_address_city"
                  :error="error('representative_address.city', { attribute: 'ciudad' })"
                  v-model="preinscription.representative_address.city" />
              </div>
              <div>
                <div class="flex">
                  <label class="font-medium block mb-2">
                      Ubicación de vivienda
                  </label>
                  <span v-if="!sameAsStudentAddress" class="text-red-600 inline-block ml-1 font-bold">
                      *
                  </span>
                </div>
                <div class="flex flex-row space-x-4">
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_location" 
                      value="Urbanización"
                      :disabled="sameAsStudentAddress"
                      :required="!sameAsStudentAddress" 
                      v-model="preinscription.representative_address.location" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_location')">
                    <span class="ml-2">Urbanización</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_location" 
                      value="Barrio"
                      :disabled="sameAsStudentAddress"
                      :required="!sameAsStudentAddress"  
                      v-model="preinscription.representative_address.location" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_location')">
                    <span class="ml-2">Barrio</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_location" 
                      value="Sector"
                      :disabled="sameAsStudentAddress"
                      :required="!sameAsStudentAddress"  
                      v-model="preinscription.representative_address.location" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_location')">
                    <span class="ml-2">Sector</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_location" 
                      value="Avenida"
                      :disabled="sameAsStudentAddress"
                      :required="!sameAsStudentAddress"  
                      v-model="preinscription.representative_address.location" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_location')">
                    <span class="ml-2">Avenida</span>
                  </label>
                </div>
                <p v-if="error('representative_address_location', { attribute: 'ubicación' })" class="mt-2 text-sm text-red-600">
                  {{ error('representative_address_location', { attribute: 'ubicación' }) }}
                </p>
              </div>
              <a-input
                :disabled="sameAsStudentAddress"
                :required="!sameAsStudentAddress"
                id="representative_address"
                :error="error('representative_address', { attribute: 'dirección' })"
                v-model="preinscription.representative_address.address" />
              <div>
                <div class="flex">
                  <label class="font-medium block mb-2">
                      Tipo de vivienda
                  </label>
                  <span v-if="!sameAsStudentAddress" class="text-red-600 inline-block ml-1 font-bold">
                      *
                  </span>
                </div>
                <div class="flex flex-row space-x-4">
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_type" 
                      value="edificio"
                      :required="!sameAsStudentAddress" 
                      :disabled="sameAsStudentAddress"
                      v-model="preinscription.representative_address.type" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_type')">
                    <span class="ml-2">Edificio</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_type" 
                      value="residencia"
                      :required="!sameAsStudentAddress" 
                      :disabled="sameAsStudentAddress"
                      v-model="preinscription.representative_address.type" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_type')">
                    <span class="ml-2">Residencia</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_type" 
                      value="conjunto residencial"
                      :required="!sameAsStudentAddress"
                      :disabled="sameAsStudentAddress" 
                      v-model="preinscription.representative_address.type" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_type')">
                    <span class="ml-2">Conjunto Residencial</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_type" 
                      value="quinta"
                      :required="!sameAsStudentAddress"
                      :disabled="sameAsStudentAddress" 
                      v-model="preinscription.representative_address.type" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_type')">
                    <span class="ml-2">Quinta</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input type="radio" 
                      name="representative_address_type" 
                      value="casa"
                      :required="!sameAsStudentAddress"
                      :disabled="sameAsStudentAddress" 
                      v-model="preinscription.representative_address.type" 
                      class="form-radio h-4 w-4 text-blue-600"
                      @change="removeError('representative_address_type')">
                    <span class="ml-2">Casa</span>
                  </label>
                </div>
                <p v-if="error('representative_address_type', { attribute: 'tipo' })" class="mt-2 text-sm text-red-600">
                  {{ error('representative_address_type', { attribute: 'tipo' }) }}
                </p>
              </div>
              <a-input
                :required="!sameAsStudentAddress"
                :disabled="sameAsStudentAddress"
                id="representative_address_number"
                :error="error('representative_address_number', { attribute: 'número' })"
                v-model="preinscription.representative_address.number" />
          </div>
  
          <!-- ------------------------------------------ -->
  
          <div class="space-y-4">
            <h3 class="font-bold text-gray-800 text-lg">
              Datos de pago
            </h3>
            <div class="flex">
              <label class="font-medium block mb-2">
                Tipo de pago
              </label>
              <span class="text-red-600 inline-block ml-1 font-bold">
                *
              </span>
            </div>
            <div class="flex flex-row space-x-4">
              <label class="inline-flex items-center">
                <input type="radio" 
                  id="cash_down" 
                  value="cash_down"
                  v-model="preinscription.payment_option"
                  :error="error('payment_option', { attribute: 'de contado' })" 
                  class="form-radio h-4 w-4 text-blue-600">
                <span class="ml-2">De contado</span>
              </label>
              <label class="inline-flex items-center">
                <input type="radio" 
                  id="financed" 
                  value="financed"
                  v-model="preinscription.payment_option"
                  :error="error('payment_option', { attribute: 'financiado' })" 
                  class="form-radio h-4 w-4 text-blue-600">
                <span class="ml-2">Financiado</span>
              </label>
            </div>
            <a-input
              label="Fecha de pago"
              required
              id="payment_date"
              type="date"
              :error="error('payment_date', { attribute: 'fecha de pago', format: (value) => value, date: (value) => value })"
              v-model="preinscription.payment_date" />
            <a-input
              label="Total pagado"
              required
              type="number"
              id="payment_amount"
              :error="error('payment_amount', { attribute: 'total pagado' })"
              v-model.number="preinscription.payment_amount" />
            <a-select
              label="Método de pago"
              required
              :source="[{label: 'Efectivo', value: 1}, {label: 'Transferencia bancaria', value: 3}, {label: 'Zelle', value: 5}]"
              :error="error('payment_type', { attribute: 'método de pago' })"
              v-model="preinscription.payment_type" />
            <a-input
              label="Número de referencia"
              :required="preinscription.payment_type !== 1"
              :disabled="preinscription.payment_type === 1"
              id="payment_receipt_number"
              :error="error('payment_receipt_number', { attribute: 'número de referencia' })"
              v-model="preinscription.payment_receipt_number" />
          </div>
  
          <div class="flex justify-between">
            <a-button outlined @click="redirectToDisegno">
              Regresar a disegno
            </a-button>
            <a-button type="submit" mode="primary" :disabled="loadingStudents || loadings.reinscription" :loading="loading">
              Guardar cambios
            </a-button>
          </div>
        </form>
      </div>
      <a-modal 
        :show="showSuccessModal" 
        :title="'¡Preinscripción Exitosa!'" 
        :closable="false"
        :maskClosable="false"
        :keyboard="false">
        <div class="space-y-4">
          <p class="text-md my-4 text-center text-gray-600">
            Su preinscripción se ha completado exitosamente.
          </p>
        </div>
        <template slot="footer">
          <div class="flex justify-end px-2 mb-4">
            <a-button
              target="_blank"
              :href="`${URL}/api/download-preregistered/${preinscriptionId}`"
              outlined
            >
              Imprimir PDF
            </a-button>
            <a-button 
              class="ml-2"
              @click="redirectToDisegno"
              mode="primary" 
            >
              Continuar
            </a-button>
          </div>
        </template>
      </a-modal>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
  import { URL } from '@/constants';
  import IdcLogo from '@/components/ui/IDCLogo.vue';
  
  
  export default {
    data: () => ({
      preinscription: {
        email: '',
        id_document: '',
        first_name: '',
        second_name: '',
        last_name: '',
        second_last_name: '',
        gender: '',
        phone: '',
        mobile_phone: '',
        birth_place: '',
        birth_date: '',
        personal_condition: '',
        address: {
          state: '',
          city: '',
          location: 'Urbanización',
          address: '',
          type: 'edificio',
          number: ''
        },
        representative_address: {
          state: '',
          city: '',
          location: 'Urbanización',
          address: '',
          type: 'edificio',
          number: ''
        },
        specialization_id: '',
        schedule_shift: '',
        payment_option: 'cash_down',
        payment_date: '',
        payment_amount: '',
        payment_type: '',
        payment_receipt_number: '',
        educational_title: '',
        educational_background: '',
        educational_institution_name: '',
        educational_graduation_date: '',
        educational_current_activity: '',
        representative_email: '',
        representative_name: '',
        representative_profession: '',
        period_id: '',
        social_networks: {
          twitter: '',
          facebook: '',
          instagram: ''
        }
      },
      timeout: null,
      isInscription: false,
      isReinscription: false,
      isFounded:false,
      shouldBeDisabled: true,
      specializationsData: [],
      changeStudentSpecialization: {
        inscription_ids: [],
        target_specialization_id: null,
        target_pensum_id: null,
        target_semester_id: null
      },
      semesterNumber: '',
      pensumName: '',
      loadings: {
        enabledPeriod: false,
        reinscription: false,
      },
      period: {
        settings: {
          schedule_shifts: []
        }
      },
      hasCondition: false,
      sameAsStudentAddress: false,
      showSuccessModal: false,
      displayNotApplyName: false,
      displayNotApplyLastName: false,
      URL,
      preinscriptionId: null
    }),
    computed: {
      ...mapGetters({
        hasError: 'hasError',
        error: 'error',
        lang: 'lang'
      }),
      ...mapState({
        preinscriptions: state => state.preinscriptions.pagination.data,
        currentPreinscriptionOnState: state => state.preinscriptions.current,
        currentStudentOnState: state => state.students.current,
        student: state => state.students.pagination.data,
        loading: state => state.preinscriptions.loading,
        loadingSpecialization: state => state.specializations.loading,
        specializations: state => state.specializations.all,
        loadingStudents: state => state.students.loading ? state.students.loading : state.preinscriptions.loading,
        pensumsData: state => state.periods.pensums.data,
        semesters: state => state.semesters.all
      })
    },
    methods: {
      ...mapMutations({
        setStudent: 'students/SET'
      }),
      ...mapActions({
        removeError: 'removeError',
        fetchStudents: 'students/index',
        fetchStudent: 'students/show',
        reInscript: 'students/reInscript',
        fetchPensums: 'periods/enabledWithPensums',
        fetchSemesters: 'semesters/fetchSemesters'
      }),

      handleDisplay(first){
        if(first){
          this.displayNotApplyName = !this.displayNotApplyName;
        }else{
          this.displayNotApplyLastName = !this.displayNotApplyLastName;
        }
      },
      handleSelection(selectedValue, first) {
        if(first){
          if(this.preinscription.second_name !== selectedValue){
            document.getElementById('second_name').value = selectedValue;
          }
          this.displayNotApplyName = !this.displayNotApplyName;
          this.preinscription.second_name = selectedValue;
        }else{
          if(this.preinscription.second_last_name !== selectedValue){
            document.getElementById('second_last_name').value = selectedValue;
          }
          this.displayNotApplyLastName = !this.displayNotApplyLastName;
          this.preinscription.second_last_name = selectedValue;
        }
      },
      handleOutsideClick(event) {
        if (
          event.target.id !== "second_last_name_arrow"
          && event.target.id !== "second_last_name"
          && event.target.id !== "second_name_arrow"
          && event.target.id !== "second_name"
        ) {
          this.displayNotApplyName = false;
          this.displayNotApplyLastName = false;
        }
      },
      submit() {
        let data = { 
          ...this.preinscription
         };
        if(this.preinscription.second_name === "No aplica"){
          data.second_name = ''
        }
        if(this.preinscription.second_last_name === "No aplica"){
          data.second_last_name = ''
        }
        data.first_name = `${this.preinscription.first_name} ${this.preinscription.second_name}`;
        data.last_name = `${this.preinscription.last_name} ${this.preinscription.second_last_name}`;
        data.address = `${this.preinscription.address.location} ${this.preinscription.address.address}, ${this.preinscription.address.type} ${this.preinscription.address.number}, ${this.preinscription.address.city}, ${this.preinscription.address.state}`;
        if(this.sameAsStudentAddress){
          data.representative_address =  data.address;
        } else{
          data.representative_address = `${this.preinscription.representative_address.location} ${this.preinscription.representative_address.address}, ${this.preinscription.representative_address.type} ${this.preinscription.representative_address.number}, ${this.preinscription.representative_address.city}, ${this.preinscription.representative_address.state}`;
        }
        if(this.preinscription.payment_type === 1){
          data.payment_receipt_number = "";
        }
        this.$repository.preinscriptions
          .store(data).then((response) => {
            this.preinscriptionId = response.data.id;
            this.showSuccessModal = true;
          });
      },
      redirectToDisegno() {
        window.location.href = 'https://www.disegno.com/';
      },
      handleSameAsStudentAddress(checked) {
        this.sameAsStudentAddress = checked;
      },
    },
    components: {
    IdcLogo
    },
    watch: {
      hasCondition(val) {
        if (!val) {
          this.preinscription.personal_condition = '';
        }
      },
      displayNotApplyName(val) {
        if(val){
          document.addEventListener('click', this.handleOutsideClick);
        } else {
          document.removeEventListener('click', this.handleOutsideClick);
        }
      },
      displayNotApplyLastName(val) {
        if(val){
          document.addEventListener('click', this.handleOutsideClick);
        } else {
          document.removeEventListener('click', this.handleOutsideClick);
        }
      }
    },
    created() {
      this.$repository.specializations
        .index({ limit: 0 }).then(({ data }) => {
        this.specializationsData = 
          data.filter(spec => spec.id !== 1 && spec.id !== 2 )
            .map(spec => ({ label: spec.title, value: spec.id }));
      })
  
      this.loadings.enabledPeriod = !this.loadings.enabledPeriod
      this.$repository.periods
        .enabled()
        .then(({ data }) => {
          this.period = {
            ...data,
            settings: {
              ...data.settings,
              schedule_shifts: data.settings.schedule_shifts.map($0 => ({
                label: this.lang.common.schedule_shifts[$0],
                value: $0
              }))
            }
          }
        })
        .finally(() => this.loadings.enabledPeriod = !this.loadings.enabledPeriod)
    }
  }
  </script>